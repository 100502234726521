export const environment = {
    production: false,

    API_ENDPOINT: 'https://local-express-api.zahidhasan.online/api/v1/',
    // API_ENDPOINT: 'http://localhost:3000/api/v1/',

    medium_editor_config: {
        placeholder: false,
        paste: {
            /* This example includes the default options for paste,
         if nothing is passed this is what it used */
            forcePlainText: false,
            cleanPastedHTML: true,
            cleanReplacements: [],
            cleanAttrs: ['class', 'style', 'dir', 'name'],
            cleanTags: ['meta'],
            unwrapTags: [],
        },
        toolbar: {
            /* These are the default options for the toolbar,
         if nothing is passed this is what is used */
            allowMultiParagraphSelection: true,
            buttons: [
                'bold',
                'italic',
                'underline',
                'subscript',
                'superscript',
                'anchor',
                'quote',
                'pre',
                'orderedlist',
                'unorderedlist',
                'indent',
                'justifyLeft',
                'justifyCenter',
                'justifyRight',
                'justifyFull',
                'h1',
                'h2',
                'h3',
                'h4',
                'h5',
                'h6',
            ],
            diffLeft: 0,
            diffTop: -10,
            firstButtonClass: 'medium-editor-button-first',
            lastButtonClass: 'medium-editor-button-last',
            relativeContainer: null,
            standardizeSelectionStart: false,
            static: false,
            /* options which only apply when static is true */
            align: 'center',
            sticky: false,
            updateOnEmptySelection: false,
        },
    },

    // firebase: {
    //   apiKey: "Your Api Key",
    //   authDomain: "Your Auth Domain",
    //   databaseURL: "Your Database Url",
    //   projectId: "Your Project Id",
    //   storageBucket: "Your StorageBucket url",
    //   messagingSenderId: "Your Sender Id"
    // }
};
